<template>
  <div class="container mb-12">
    <div class="flex">
      <div class="flex-1">
        <div class="rounded-xl relative bg-cover bg-center flex items-end" style="min-height: 600px; background-image: linear-gradient(90deg, rgba(24, 36, 76, 0.5) 0%, rgba(24, 36, 76, 0.5) 80%), url('img/bg.webp');">
          <!-- Aquí va el contenido del encabezado -->
          <div class="z-10 my-24 px-4 sm:px-6 lg:px-8">
            <img src="img/logo-2.webp" alt="..." class="h-24 md:h-32 lg:h-40" />

            <!-- <h1 class="font-archivo-black font-extrabold text-6xl sm:text-8xl text-pantone-281c">CHAVA</h1>
            <p class="font-archivo-black font-extrabold tracking-widest text-4xl sm:text-6xl text-pantone-311c">ALCALÁ</p> -->
          </div>
          <div class="absolute bottom-0 left-0 right-0">
            <!-- Aquí va el contenido del pie del componente -->
            <img src="img/wave.svg" alt="..." class="w-full" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: '',
  }
</script>

<style lang="scss" scoped>

</style>