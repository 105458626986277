<template>
  <!-- <header> -->
    <HeaderComponent />
  <!-- </header> -->

  <header>
    <ImageHeaderComponent />
  </header>

  <main class="container">
    <div class="grid grid-cols-12">
      <div class="col-span-12">
        <BannerComponent />
      </div>
      <div class="col-span-12 md:col-span-4 p-4 md:p-8">
        <div class="flex justify-center">
          <img src="img/logo-v.webp" alt="..." />
        </div>
      </div>
      <div class="col-span-12 md:col-span-8 p-4 md:p-8">
        <img src="img/slogan.webp" alt="..." class="img-fluid" />
      </div>
    </div>
  </main>

  <footer>
    <FooterComponent />
  </footer>
</template>

<script>
import HeaderComponent from '@/components/HeaderComponent.vue';
import ImageHeaderComponent from '@/components/ImageHeaderComponent.vue';
import BannerComponent from '@/components/BannerComponent.vue';
import FooterComponent from '@/components/FooterComponent.vue';

export default {
  name: 'App',
  components: {
    HeaderComponent,
    ImageHeaderComponent,
    BannerComponent,
    FooterComponent,
  }
}
</script>

<style lang="scss" scoped>

</style>
