<template>
    <div class="container-fluid mb-12">
      <div class="grid grid-cols-12">
        <div class="col-span-12">
          <div class="flex justify-center bg-white border rounded-xl shadow-xl py-12">
            <img src="img/logo-2.webp" alt="..." class="h-24 md:h-32" />
          </div>
        </div>
      </div>
    </div>
</template>

<script>
export default {
  name: 'BannerComponent',
}
</script>

<style lang="scss" scoped>

</style>