<template>
  <div class="container-fluid bg-white shadow-lg sticky top-0 z-20 mb-12">
    <div class="flex items-center justify-between py-4 px-8">
      <div class="flex justify-center">
        <img src="img/logo-2.webp" alt="..." class="h-16" />
        <!-- <h1 class="font-archivo-black text-xl font-bold ml-4">Chava Alcalá</h1> -->
      </div>
      <!-- Aquí puedes agregar más elementos del header, como un menú de navegación -->
    </div>
  </div>
</template>

<script>
  export default {
    name: 'HeaderComponent',
  }
</script>

<style lang="scss" scoped>

</style>